import "./Competencies.scss";
import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChess, faSearch } from "@fortawesome/free-solid-svg-icons";

export default class Competencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompetency: ""
    };
  }

  selectCompetency = (competency) => {
    let researchCard = document.getElementById("competency-research");
    let solutionsCard = document.getElementById("competency-solutions");

    if (competency === "research") {
      this.setState(
        {
          selectedCompetency: "research"
        },
        () => {
          solutionsCard.classList.remove("competency-card-selected");
          solutionsCard.classList.add("competency-card");
          researchCard.classList.remove("competency-card");
          researchCard.classList.add("competency-card-selected");
        }
      );
    } else {
      this.setState(
        {
          selectedCompetency: "solutions"
        },
        () => {
          researchCard.classList.remove("competency-card-selected");
          researchCard.classList.add("competency-card");
          solutionsCard.classList.remove("competency-card");
          solutionsCard.classList.add("competency-card-selected");
        }
      );
    }
  };

  render() {
    return (
      <div id="competencies-container">
        <h1>Our Strength and Competencies</h1>
        <div id="competencies-selection">
          <div
            onClick={() => {
              this.selectCompetency("research");
            }}
            className="competency-card"
            id="competency-research"
          >
            <FontAwesomeIcon size="6x" icon={faSearch}></FontAwesomeIcon>
          </div>
          <div
            onClick={() => {
              this.selectCompetency("solutions");
            }}
            className="competency-card"
            id="competency-solutions"
          >
            <FontAwesomeIcon size="6x" icon={faChess}></FontAwesomeIcon>
          </div>
        </div>
        {this.state.selectedCompetency === "research" && (
          <div className="competencies-content-block">
            <h3>
              At Polarify Consulting, our competencies are the cornerstone of
              our ability to deliver transformative services and strategic
              solutions to our clients. Each competency is honed to align with
              our diverse services, ensuring that our strategic proficiency
              drives impactful outcomes. Explore our core competenci es and the
              strategic solutions they bring forth, empowering organizations to
              navigate challenges and achieve enduring success.
            </h3>
          </div>
        )}
        {this.state.selectedCompetency === "solutions" && (
          <div className="competencies-content-block">
            <h2>Competencies</h2>
            <h3>Analytical Rigor</h3>

            <p>
              Our deep analytical rigor forms the foundation of all our
              services. From market insights to risk assessments, our competency
              in rigorous analysis enables clients to make informed and
              data-driven decisions, fostering precision in strategic planning.
            </p>
            <h3>Innovation and Creativity:</h3>
            <p>
              Anchored in creativity, our competency in innovation drives
              strategic solutions that transcend conventional boundaries. From
              crafting novel business models to fostering a culture of
              continuous improvement, innovation is at the heart of our
              strategic approach.
            </p>
            <h3>Industry Expertise:</h3>
            <p>
              Our competencies encompass a comprehensive understanding of
              diverse industries. This expertise enables us to tailor strategic
              solutions that resonate with the unique challenges and
              opportunities within each industry, fostering targeted and
              industry-specific excellence.
            </p>
            <h3>Sustainability Stewardship:</h3>
            <p>
              Competent in sustainability practices, we integrates responsible
              business strategies into every solution. From ESG frameworks to
              eco-friendly processes, our competency in sustainability fosters
              solutions that contribute to a socially responsible and
              environmentally conscious future.
            </p>
            <h3>Strategic Partnerships and Alliances:</h3>
            <p>
              Forge strategic collaborations that amplify success. Explore our
              solutions for building partnerships with industry players,
              governmental agencies, and international entities, contributing to
              a synergistic and impactful Arctic presence.
            </p>
          </div>
        )}
      </div>
    );
  }
}
