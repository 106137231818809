import "./Ethos.scss";
import { Component } from "react";

export default class Ethos extends Component {
  render() {
    return (
      <div id="ethos-container">
        <h1>Ethos: Exemplary Expertise and Unparalleled Distinction</h1>
        <div className="ethos-content-block">
          <p>
            At Polarify Consulting, our ethos is defined by a commitment to
            exemplary expertise and an unwavering dedication to setting
            ourselves apart in the dynamic realm of strategic advisory. Our
            proficiency is not merely a product of experience; it is the
            culmination of a relentless pursuit of excellence, a commitment to
            continuous learning, and an unyielding passion for delivering
            transformative results.
          </p>
        </div>
        <div className="ethos-content-block">
          <h2>Expertise:</h2>
          <p>
            Polarify Consulting stands as a bastion of strategic prowess, where
            Our expertise gets honed through a rigorous blend of industry
            acumen, cutting-edge methodologies, and a deep understanding of the
            intricacies that define our clients' unique challenges. Whether
            navigating market shifts, optimizing operations, or charting growth
            trajectories, our consulting practises bring to bear a wealth of
            knowledge that goes beyond conventional approaches.
          </p>
        </div>
        <div className="ethos-content-block">
          <h2>What Sets Us Apart:</h2>
          <div className="ethos-content-subsection">
            <h3>Innovation, Insight, Impact:</h3>
            <p>
              What sets Polarify Consulting apart is our unwavering commitment
              to innovation, the depth of our strategic insight, and the
              tangible impact we create for our clients. We don't just solve
              problems; we redefine possibilities. Our consultants are driven by
              a shared ethos to challenge the status quo, to look beyond the
              obvious, and to craft solutions that resonate with vision and
              ambition
            </p>
          </div>
        </div>
        <div className="ethos-content-block">
          <h2>The Polarify Advantage:</h2>
          <div className="ethos-content-subsection">
            <h3>Client-Centric Collaboration:</h3>
            <p>
              Our success is intertwined with that of our clients. We prioritize
              collaborative partnerships, ensuring that every solution is
              tailor-made to meet the specific needs and aspirations of the
              organizations we serve.
            </p>
          </div>
          <div className="ethos-content-subsection">
            <h3>Analytical Rigor:</h3>
            <p>
              Polarify Consulting is synonymous with analytical rigor. We bring
              a disciplined approach to problem-solving, leveraging data-driven
              insights that form the bedrock of our strategic recommendations.
            </p>
          </div>
          <div className="ethos-content-subsection">
            <h3>Innovative Methodologies:</h3>
            <p>
              Innovation is not just encouraged; it's ingrained in our DNA. Our
              consultanting practise employs innovative methodologies that push
              the boundaries of conventional thinking, driving outcomes that
              redefine industry benchmarks.
            </p>
          </div>
          <h2>Long-Term Vision:</h2>
          <p>
            Beyond immediate challenges, we craft strategies with a long-term
            vision. Our focus extends beyond quick fixes to solutions that pave
            the way for sustained success, resilience, and adaptability in an
            ever-evolving business environment. At Polarify Consulting, our
            ethos is not just a statement; it's a lived reality. It's an
            unwavering commitment to excellence, a celebration of innovation,
            and a promise to be the transformative force that propels our
            clients towards enduring success.
          </p>
        </div>
      </div>
    );
  }
}
