import "./Contact.scss";
import { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div id="contact-container">
        <h2> Contact Polarify Consulting to embark on a future of synergies and prosperity.</h2>
        <p>
          Whether you're an industry leader, an investor, Government agency or a community member,
          we invite you to partner with us on your transformative journey. Together, we
          can create a future where prosperity coexists with
          sustainability.
        </p>
        <div id="contact-information">
          <h2>Contact us:</h2>
          <p>Email ID: info@polarifyconsulting.com</p>
        </div>
      </div>
    );
  }
}
