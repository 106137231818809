import "./Home.scss";
import { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default class Home extends Component {
  render() {
    return (
      <div id="home-container">
        <div id="home-welcome-message">
          <h1>Welcome to Polarify!</h1>
          <img
            id="home-logo-img"
            alt="Polarify Logo"
            width={"720px"}
            height={"480px"}
            src="/images/polarify-logo-new.jpg"
          ></img>
          <h2>
            Polarify Consulting - Navigating Consulting Frontiers With Research
            And Strategic Solutions
          </h2>
        </div>
        <div className="home-content-block">
          <h3>About Us:</h3>
          <p>
            Welcome to Polarify Consulting, a leader in strategic advisory
            services. With an unwavering commitment to excellence, innovation,
            and client success, we navigate the complexities of today's business
            landscape with precision and insight. Founded on the principles of
            collaboration and transformative thinking, Polarify Consulting will
            stand as a beacon of strategic brilliance, empowering organizations
            to surpass their goals and redefine their futures.
          </p>
        </div>
        <div className="home-content-block">
          <h3>Our Vision:</h3>
          <p>
            Our vision at Polarify Consulting is to be the catalyst for
            transformative excellence in every industry we touch. We aspire to
            lead the way in reshaping business paradigms, fostering sustainable
            growth, and leaving an indelible mark on the global landscape.
            Guided by a vision of continuous innovation and unwavering client
            partnership, we strive to set new benchmarks for strategic
            brilliance and redefine the future of consulting.
          </p>
        </div>
        <div className="home-content-block">
          <h3>Our Mission:</h3>
          <p>
            At Polarify Consulting, our mission is to empower our clients to
            realize their fullest potential and drive lasting impact. Through a
            dynamic blend of analytical rigor, industry expertise, and
            collaborative partnerships, we craft bespoke solutions that not only
            address current challenges but also pave the way for enduring
            success. Our mission is rooted in the belief that by helping our
            clients thrive, we contribute to the collective advancement of
            industries and economies worldwide.
          </p>
        </div>
        <div id="home-image-container">
          <Carousel>
            <div>
              <img className="home-carousel-image" alt="A Ship in the Arctic" src="/images/ship.png" />
              <h1 className="legend">
                Brushstrokes on the canvas of resource discovery.
              </h1>
            </div>
            <div>
              <img className="home-carousel-image" alt="Eskimos in the Arctic" src="/images/eskimos.png" />
              <h1 className="legend">Cementing pathway to new horizons.</h1>
            </div>
            <div>
              <img className="home-carousel-image"
                alt="Habitation in the Arctic"
                src="/images/ice-habitation.png"
              />
              <h1 className="legend">
                Delving the realms of unexplored riches.
              </h1>
            </div>
            <div>
              <img className="home-carousel-image" alt="Ships in the Arctic" src="/images/ships.png" />
              <h1 className="legend">
                Unveiling hidden treasures of resources and opportunities.
              </h1>
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}
