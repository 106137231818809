import "./App.scss";
import Header from "./components/header/Header";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Ethos from "./components/ethos/Ethos";
import ServicesProvided from "./components/services-provided/ServicesProvided";
import Competencies from "./components/competencies/Competencies";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";

function App() {
  return (
    <div id="app-container">
      <Header></Header>
      <hr></hr>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/ethos" element={<Ethos></Ethos>}></Route>
        <Route path="/services" element={<ServicesProvided></ServicesProvided>}></Route>
        <Route path="/competencies" element={<Competencies></Competencies>}></Route>
        <Route path="/blog" element={<Blog></Blog>}></Route>
        <Route path="/contact-us" element={<Contact></Contact>}></Route>
      </Routes>
    </div>
  );
}

export default App;
