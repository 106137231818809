import "./ServicesProvided.scss";
import { Component } from "react";

export default class ServicesProvided extends Component {
  render() {
    return (
      <div id="services-container">
        {/* <div className="services-content-block">
          <h2>Generative AI Consulting:</h2>
          <p>
          Unlock the potential of artificial intelligence with our Generative AI Consulting services. From custom model development to implementation strategies, we guide
           organizations in harnessing the power of generative AI for innovative solutions, automation, and enhanced decision-making.
          </p>
        </div> */}
        <div className="services-content-block">
          <h2>Innovation and R&D Strategy:</h2>
          <p>
          Navigate the landscape of innovation with our Innovation and R&D Strategy services. We collaborate with clients to foster a culture of creativity, drive product/service differentiation, and formulate strategies that position organizations at the forefront of their industries.

          </p>
        </div>
        <div className="services-content-block">
          <h2>Risk Management:</h2>
          <p>
          Mitigate uncertainties and navigate challenges effectively through our Risk Management services. We employ a structur
          ed approach to identify, assess, and address risks, ensuring organizations operate with resilience and strategic foresight.
          </p>
        </div>
        <div className="services-content-block">
          <h2>Sustainability and ESG:</h2>
          <p>
          Embed responsible business practices into your organizational DNA with our Sustainability and ESG services. From environmental impact ass
          essments to social responsibility frameworks, we guide clients in aligning their strategies with global sustainability goals.
          </p>
        </div>
        <div className="services-content-block">
          <h2>Renewable Energy Advisory:</h2>
          <p>
          Embrace clean and sustainable energy solutions with our Renewable Energy Advisory services. From project feasibility assessments to implementation, we assist clients in navigating the complexities of renewable energy, contributing to a greener and more sustainable future.
          </p>
        </div>
        <div className="services-content-block">
          <h2>Geo Political Foresight:</h2>
          <p>
          Gain strategic advantage with our Geo Political Foresight services. We analyze global geopolitical trends, providing organizations with insights that inform decision-making, risk assessment, and long-term planning in an ever-evolving geopolitical landscape.
          </p>
        </div>
      </div>
    );
  }
}
