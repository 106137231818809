import "./Header.scss";
import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTray: false
    };
  }

  toggleTray = () => {
    this.setState({
      showTray: !this.state.showTray
    });
  };

  render() {
    return (
      <>
        <div id="header-container">
          <h1>Polarify</h1>
          <div id="header-menu">
            <NavLink
              id="header-home-navlink"
              to={"/"}
              className={(status) => {
                return status.isActive
                  ? "header-menu-item-selected"
                  : "header-menu-item";
              }}
            >
              <div>Home</div>
            </NavLink>
            <NavLink
              id="header-ethos-navlink"
              to={"/ethos"}
              className={(status) => {
                return status.isActive
                  ? "header-menu-item-selected"
                  : "header-menu-item";
              }}
            >
              <div>Ethos</div>
            </NavLink>
            <NavLink
              id="header-services-navlink"
              to={"/services"}
              className={(status) => {
                return status.isActive
                  ? "header-menu-item-selected"
                  : "header-menu-item";
              }}
            >
              <div>Services</div>
            </NavLink>
            <NavLink
              id="header-competencies-navlink"
              to={"/competencies"}
              className={(status) => {
                return status.isActive
                  ? "header-menu-item-selected"
                  : "header-menu-item";
              }}
            >
              <div>Competencies</div>
            </NavLink>
            <NavLink
              id="header-blog-navlink"
              to={"/blog"}
              className={(status) => {
                return status.isActive
                  ? "header-menu-item-selected"
                  : "header-menu-item";
              }}
            >
              <div>Featured Insights</div>
            </NavLink>
            {/* <div className="header-menu-item">FAQ</div> */}
            <NavLink
              id="header-blog-navlink"
              to={"/contact-us"}
              className={(status) => {
                return status.isActive
                  ? "header-menu-item-selected"
                  : "header-menu-item";
              }}
            >
              <div>Contact</div>
            </NavLink>
          </div>
          <div id="header-socials-container">
            <a href="mailto:webmaster@example.com">
              <FontAwesomeIcon
                size="2x"
                className="header-icon"
                icon={faEnvelope}
              />
            </a>
          </div>
        </div>
        <div id="header-container-mobile">
          <FontAwesomeIcon onClick={this.toggleTray} size="2x" icon={faBars} />
          <h1>Polarify</h1>
        </div>
        {this.state.showTray && (
          <div id="header-mobile-tray">
            <div id="header-menu-mobile">
              <NavLink
              onClick={this.toggleTray}
                id="header-home-navlink"
                to={"/"}
                className={(status) => {
                  return status.isActive
                    ? "header-menu-item-selected"
                    : "header-menu-item";
                }}
              >
                <div>Home</div>
              </NavLink>
              <NavLink
              onClick={this.toggleTray}
                id="header-ethos-navlink"
                to={"/ethos"}
                className={(status) => {
                  return status.isActive
                    ? "header-menu-item-selected"
                    : "header-menu-item";
                }}
              >
                <div>Ethos</div>
              </NavLink>
              <NavLink
              onClick={this.toggleTray}
                id="header-services-navlink"
                to={"/services"}
                className={(status) => {
                  return status.isActive
                    ? "header-menu-item-selected"
                    : "header-menu-item";
                }}
              >
                <div>Services</div>
              </NavLink>
              <NavLink
              onClick={this.toggleTray}
                id="header-competencies-navlink"
                to={"/competencies"}
                className={(status) => {
                  return status.isActive
                    ? "header-menu-item-selected"
                    : "header-menu-item";
                }}
              >
                <div>Competencies</div>
              </NavLink>
              <NavLink
              onClick={this.toggleTray}
                id="header-blog-navlink"
                to={"/blog"}
                className={(status) => {
                  return status.isActive
                    ? "header-menu-item-selected"
                    : "header-menu-item";
                }}
              >
                <div>Featured Insights</div>
              </NavLink>
              {/* <div className="header-menu-item">FAQ</div> */}
              <NavLink
              onClick={this.toggleTray}
                id="header-blog-navlink"
                to={"/contact-us"}
                className={(status) => {
                  return status.isActive
                    ? "header-menu-item-selected"
                    : "header-menu-item";
                }}
              >
                <div>Contact</div>
              </NavLink>
            </div>
          </div>
        )}
      </>
    );
  }
}
